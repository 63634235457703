import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const Unauthorized = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Card sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%' }} elevation={0}>
      <CardContent>
        <Typography variant="h5">Please Login to Continue</Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{ textAlign: 'center', width: '100%' }}
          color="primary"
          variant="contained"
          onClick={() => {
            loginWithRedirect({ redirect_uri: `${window.location.origin}/` });
          }}
        >
          Login
        </Button>
      </CardActions>
    </Card>
  );
};

export default Unauthorized;
