import React, { FC, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';

export const NavBarProfile: FC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localLocation, setLocation] = useState<string>('');
  const { user, logout } = useAuth0();
  const location = useLocation();
  const profileMenuIsOpen = Boolean(anchorEl);

  useEffect(() => {
    if (localLocation !== location.pathname) {
      setLocation(location.pathname);
      setAnchorEl(null);
    }
  }, [location, localLocation, setAnchorEl, setLocation]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={'profile-menu'}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={profileMenuIsOpen}
      onClose={handleMenuClose}
    >
      <ListSubheader sx={{ 'margin-top': '45px' }} component="div" id="nested-list-subheader">
        {user?.name || user?.email}
      </ListSubheader>
      <Divider />
      <MenuItem component={RouterNavLink} to="/profile">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <Typography variant="inherit">Profile</Typography>
      </MenuItem>
      <MenuItem onClick={logoutWithRedirect}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Logout</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton onClick={handleProfileMenuOpen}>
        <PersonIcon />
      </IconButton>
      {renderMenu}
    </>
  );
};

export default NavBarProfile;
