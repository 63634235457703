import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Service } from '../services';

export interface ServerInfoProps {
  service: Service;
  selectedEnv: string;
  specUrl: string;
  buildNumber: string;
  onEnvSelect: (env: string) => void;
}

const ServerInfo: React.FC<ServerInfoProps> = ({ service, selectedEnv, specUrl, buildNumber, onEnvSelect }) => {
  const { icon: Icon } = service;

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 545;

  const isDesktop = width > breakpoint;

  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  return (
    <div className="info">
      <TableContainer>
        <Table sx={{ minWidth: isDesktop ? 650 : 300 }} size="small" aria-label="spec summary">
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '40px' }}>
                <Icon style={{ fontSize: 40 }} />
              </TableCell>
              <TableCell sx={{ width: '180px' }}>{`build id ${buildNumber || 'unknown'}`}</TableCell>
              {isDesktop && <TableCell>{specUrl}</TableCell>}
              <TableCell align="right">
                <FormControl sx={{ textAlign: 'left', minWidth: '120px' }}>
                  <InputLabel id="env-select-label">environment</InputLabel>
                  <Select
                    labelId="env-select-label"
                    id="env-select"
                    value={selectedEnv}
                    label="environment"
                    onChange={(e) => onEnvSelect(e.target.value as any)}
                  >
                    {service.envs.map((env) => (
                      <MenuItem key={env} value={env}>
                        {env}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServerInfo;
