import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import NavBarProfile from './NavBarProfile';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getServices } from '../services';

const drawerWidth = 240;

const StyledToolbar = styled('div')(
  ({ theme }) => `
  display: 'flex';
  alignItems: 'center';
  justifyContent: 'flex-end';
  padding: ${theme.spacing(0, 1)};
  margin-top: 20px;
`,
);

const services = getServices();
const navItems = Object.values(services);

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [localLocation, setLocation] = useState<string>('');
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (localLocation !== location.pathname) {
      setLocation(location.pathname);
      setOpen(false);
    }
  }, [location, localLocation, setOpen, setLocation]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const unitName = location.pathname.replace('/', '');

  return (
    <>
      <AppBar className="linq-app-bar mdc-top-app-bar app-bar" aria-label="Main header for the site.">
        <Toolbar>
          {isAuthenticated && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography className={`mdc-top-app-bar__title`} sx={{ flexGrow: 1 }}>
            <Box
              className={`linq`}
              sx={{
                display: 'flex',
                '& img': {
                  display: 'inline',
                  filter: 'grayscale(1) brightness(100)',
                  height: '16px',
                },
              }}
            >
              <img src="LINQ-logo.svg" alt="LINQ" />
            </Box>
            <span className="product-name">API Documentation</span>
            {unitName && <span className="divider"></span>}
            <span className="unit-name">{unitName}</span>
          </Typography>
          {isAuthenticated && <NavBarProfile />}
          {!isAuthenticated && (
            <Button color="inherit" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {isAuthenticated && (
        <Drawer sx={{ width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap' }} open={open}>
          <StyledToolbar>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
            <Divider />
            <List>
              {navItems.map(({ icon: Icon, label, link }) => (
                <ListItem button key={link} component={RouterNavLink} to={link}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              ))}
            </List>
          </StyledToolbar>
        </Drawer>
      )}
    </>
  );
};

export default NavBar;
