import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Unauthorized from '../views/Unauthorized';

export const PrivateRoute: FC<RouteProps> = (props) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Unauthorized />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
