import { createTheme } from '@mui/material/styles';
import { blueberryMuffin } from '@linq/snackpaq';

const theme = createTheme({
  palette: {
    primary: {
      light: blueberryMuffin.color.primary[100],
      main: blueberryMuffin.color.primary[600],
      dark: blueberryMuffin.color.primary[700],
    },
    secondary: {
      light: blueberryMuffin.color.secondary[100],
      main: blueberryMuffin.color.secondary[500],
      dark: blueberryMuffin.color.secondary[700],
    },
  },
});

export default theme;
