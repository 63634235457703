import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/Loading';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import Home from './views/Home';
import Profile from './views/Profile';
import ApiSpecComponent from './views/APISpec';
import history from './utils/history';
import theme from './theme';
import { getServices } from './services';

// styles
import './App.css';

function App() {
  const { isLoading, error } = useAuth0();
  const services = getServices();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <link
          href="https://unpkg.com/material-components-web@11.0.0/dist/material-components-web.min.css"
          rel="stylesheet"
          media="all"
        />
        <link href="https://design.linq.com/5.0.0/core/linq-snackpaq-core.css" rel="stylesheet" media="all" />
        <link href="https://design.linq.com/5.0.0/theme/blueberry-muffin.css" rel="stylesheet" media="all" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <div id="app" className="d-flex flex-column h-100">
            <NavBar />
            <main>
              <Switch>
                <Route path="/" exact component={Home} />
                <PrivateRoute path="/profile" exact component={Profile} />

                {Object.values(services).map((service) => {
                  return (
                    <PrivateRoute
                      path={service.link}
                      key={service.id}
                      render={(props) => <ApiSpecComponent {...props} serviceId={service.id} />}
                    />
                  );
                })}
              </Switch>
            </main>
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
