import CategoryIcon from '@mui/icons-material/Category';
import CreditCard from '@mui/icons-material/CreditCard';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import LockIcon from '@mui/icons-material/Lock';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Settings from '@mui/icons-material/Settings';

const defaultEnvironments = ['sandbox', 'dev', 'staging', 'uat', 'prod'];
export interface Service {
  id: string;
  icon: any;
  label: string;
  link: string;
  host: string;
  envs: string[];
  basePath?: string;
  useBasePath?: boolean;
}
export function getServices(): Record<string, Service> {
  return {
    accounts: {
      id: 'accounts',
      icon: GroupIcon,
      label: 'Accounts',
      link: '/accounts',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    agency: {
      id: 'agency',
      icon: SchoolIcon,
      label: 'Agency',
      link: '/agency',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    compliance: {
      id: 'compliance',
      icon: CategoryIcon,
      label: 'Compliance',
      link: '/compliance',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    finance: {
      id: 'finance',
      icon: MonetizationOnIcon,
      label: 'Finance',
      link: '/finance',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    payments: {
      id: 'payments',
      icon: CreditCard,
      label: 'Payments',
      link: '/payments',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    search: {
      id: 'search',
      icon: EngineeringIcon,
      label: 'Search',
      link: '/search',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    synthesis: {
      id: 'synthesis',
      icon: DeviceHubIcon,
      label: 'Synthesis',
      link: '/synthesis',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    vault: {
      id: 'vault',
      icon: LockIcon,
      label: 'Vault',
      link: '/vault',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: defaultEnvironments,
    },
    fdp: {
      id: 'fdp',
      icon: RestaurantIcon,
      label: 'FDP',
      link: '/fdp',
      host: 'fdplinq.com',
      envs: defaultEnvironments,
      useBasePath: true,
      basePath: 'core',
    },
    onlineordering: {
      id: 'onlineordering',
      icon: ShoppingCartOutlinedIcon,
      label: 'Online Ordering',
      link: '/onlineordering',
      host: 'onlineordering.linq.com',
      envs: defaultEnvironments,
      useBasePath: true,
      basePath: 'core',
    },
    configuration: {
      id: 'configuration',
      icon: Settings,
      label: 'Configuration',
      link: '/configuration',
      host: 'linqengineering.com',
      useBasePath: true,
      envs: ['dev'],
    },
  };
}
