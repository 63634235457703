import React from 'react';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { RedocStandalone } from 'redoc';
import Alert from '@mui/material/Alert';
import ServerInfo from '../components/ServerInfo';
import Loading from '../components/Loading';
import { getServices } from '../services';

export interface ApiSpecComponentProps {
  serviceId: string;
}

interface SpecState {
  doc: Record<string, any> | null;
  isLoading: boolean;
}

export const ApiSpecComponent: React.FC<ApiSpecComponentProps> = ({ serviceId }) => {
  const services = getServices();
  const service = services[serviceId];
  const history = useHistory<any>();
  const location = useLocation<any>();
  const envParam = new URLSearchParams(location.search).get('env');
  const [env, setEnv] = React.useState(
    envParam && service.envs.includes(envParam.toLowerCase())
      ? envParam.toLowerCase()
      : service.envs[service.envs.length - 1],
  );
  const [spec, setSpec] = React.useState<SpecState>({ doc: null, isLoading: true });
  const apiSubdomain = env === 'prod' ? 'api' : `${env}.api`;
  const documentUrl = service.useBasePath
    ? `${service.host}/${service.basePath || service.id}/document`
    : `${service.host}/document`;

  const specUrl = `https://${apiSubdomain}.${documentUrl}`;

  React.useEffect(() => {
    async function fetchSpec() {
      try {
        const response = await axios.get<Record<string, any>>(specUrl);
        setSpec({ doc: response.data, isLoading: false });
      } catch (err) {
        setSpec({ doc: null, isLoading: false });
      }
    }
    fetchSpec();
    history.push(`${location.pathname}?env=${env}`);
  }, [env, specUrl, history, location.pathname]);

  const renderSpec = () => {
    if (spec.doc && !spec.isLoading) {
      return (
        <RedocStandalone
          spec={spec.doc}
          options={{
            nativeScrollbars: true,
            theme: { sidebar: { backgroundColor: '#F2F2F2' }, rightPanel: { width: '30%' } },
            expandDefaultServerVariables: true,
          }}
        />
      );
    } else if (!spec.isLoading) {
      return (
        <Alert severity="warning" sx={{ height: '90px', paddingTop: '25px' }}>
          The {env} environment specification for {service.id} has not been deployed!
        </Alert>
      );
    } else {
      return <Loading />;
    }
  };

  return (
    <div>
      <ServerInfo
        service={service}
        selectedEnv={env}
        onEnvSelect={setEnv}
        specUrl={specUrl}
        buildNumber={spec.doc?.info?.buildNumber}
      />
      {renderSpec()}
    </div>
  );
};

export default ApiSpecComponent;
